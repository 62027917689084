// extracted by mini-css-extract-plugin
export var bottomText = "herosection-module--bottom-text--CsqFp";
export var buttonWrapper = "herosection-module--button-wrapper--T-aFn";
export var callToActionBtn = "herosection-module--call-to-action-btn--dR-2w";
export var content = "herosection-module--content--upusu";
export var contentWrapper = "herosection-module--content-wrapper--7Rp-J";
export var imgWrapper = "herosection-module--img-wrapper--NzFit";
export var mark = "herosection-module--mark--3tTAE";
export var secondaryActionBtn = "herosection-module--secondary-action-btn--6fmeR";
export var subtitle = "herosection-module--subtitle--L2Mrc";
export var textWrapper = "herosection-module--text-wrapper--SXAJP";
export var title = "herosection-module--title--xZMwi";
export var wrapper = "herosection-module--wrapper--ay22V";