import { HeadFC } from "gatsby";
import React from "react";
import Layout from "@/components/Layout";
import HeroSection from "@/components/business-ops/HeroSection";
import BusinessOps from "@/components/business-ops/BusinessOps";
import Achievements from "@/components/Achievements";
import ContactForm from "@/components/ContactForm";
import Modules from "@/components/Modules";
import businessOpsCover from "@/images/business-ops-cover.png";
import { GTM } from "@/components/GTM";

const IndexPage = () => {
  return (
    <Layout hero={<HeroSection />}>
      <main>
        <BusinessOps />
        <Modules />
        <Achievements />
        <ContactForm />
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <GTM />
    <title>Business Ops | Multiply</title>;
    <meta
      name="description"
      content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
    ></meta>
    <meta
      name="og:description"
      content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
    ></meta>
    <meta name="image" content={businessOpsCover}></meta>
    <meta name="og:image" content={businessOpsCover}></meta>
    <link rel="canonical" href="https://www.multiply.ai/" />
    <link rel="canonical" href="https://blog.multiply.ai/" />
  </>
);
